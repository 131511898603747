import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Body, BodyProps } from '@/components/shared/typography/Body';
import { StyledLink } from '@/components/shared/StyledLink';
import { ElementGraphic } from '@/components/shared/ElementGraphic';
import { Badge } from '@/components/shared/Badge';
import { Tooltip } from '@/components/shared/Tooltip';
import { useRoutePathContext } from '@/contexts/shared';
import { IMAGE_PLACEHOLDER_PATH } from '@/resources/constants';
import { useStopEventPropagation } from '@/hooks/dom';

import style from './BrandGraphic.module.sass';

const cx = classNames.bind(style);

type BrandGraphicProps = {
  name?: string;
  logoUrl?: string;
  imageClassName?: string;
  imageSizeVariant?: 'base' | 'medium' | 'small';
  brandId?: string;
  companyId?: string;
  nameSizeVariant?: BodyProps['size'];
  isNameVisible?: boolean;
  hasTooltip?: boolean;
};

export const BrandGraphic = ({
  brandId,
  logoUrl,
  name,
  imageClassName,
  imageSizeVariant = 'base',
  companyId,
  nameSizeVariant = 'base',
  isNameVisible = true,
  hasTooltip,
}: BrandGraphicProps) => {
  const { t } = useTranslation();

  const {
    getInstanceBrandRoute,
  } = useRoutePathContext();

  const stopPropagation = useStopEventPropagation();

  const imageClassNames = cx('image', imageClassName, imageSizeVariant);

  const brandGraphic = logoUrl && (
    <ElementGraphic
      logoUrl={logoUrl}
      name={name}
      imageClassName={imageClassNames}
    />
  );

  const brandName = name ?? t('brand:noBrand');

  const textElement = (
    <Body size={nameSizeVariant} className={style.text}>
      {brandName}
    </Body>
  );

  const renderGraphic = () => {
    if (!logoUrl) {
      return null;
    }

    if (brandId) {
      return (
        <Link
          to={getInstanceBrandRoute(brandId, companyId)}
          onClick={stopPropagation}>
          {brandGraphic}
        </Link>
      );
    }

    return brandGraphic;
  };

  const renderText = () => {
    if (!isNameVisible) {
      return null;
    }

    if (brandId) {
      return (
        <StyledLink
          to={getInstanceBrandRoute(brandId, companyId)}
          onClick={stopPropagation}>
          {textElement}
        </StyledLink>
      );
    }

    return textElement;
  };

  const renderGraphicWithTooltip = () => {
    if (logoUrl?.includes(IMAGE_PLACEHOLDER_PATH)) {
      return (
        <div>
          <Badge
            variant='neutral'
            shapeVariant='rectangle'
            sizeVariant='small'>
            {brandName}
          </Badge>
        </div>
      );
    }

    return (
      <Tooltip content={brandName}>
        <div>
          {brandGraphic}
        </div>
      </Tooltip>
    );
  };

  return (
    <div className={style.brandWrapper}>
      {
        hasTooltip
          ? renderGraphicWithTooltip()
          : renderGraphic()
      }
      <div className={style.textWrapper}>
        {renderText()}
      </div>
    </div>
  );
};
