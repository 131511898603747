import { useTranslation } from 'react-i18next';

import { PageSectionHeader } from '@/components/shared/PageSectionHeader';
import { Container } from '@/components/shared/containers/Container';
import { ViewModeSelectorContext } from '@/components/shared/viewMode/ViewModeSelectorContext';
import { StoresView } from '@/components/store/StoresView';
import { StoresExportButton } from '@/components/store/StoresExportButton';
import { usePaginationParams } from '@/hooks/shared';
import { useStores, useStoresFilterParams, useStoresSortParams } from '@/hooks/stores';
import { useFilteredStoresQueriesMapping } from '@/pages/store/StoresPage/utils';
import { ViewMode } from '@/resources/enums';
import { parens } from '@/utils/string';
import { usePersistQueryString } from '@/hooks/browser';
import { UseStoreParams } from '@/hooks/stores/useCompanyStores';
import { getStoresRoute } from '@/utils/routes';
import { usePersistedValue } from '@/hooks/utils';

const permittedValues = [ViewMode.Grid, ViewMode.List];

export const StoresPage = () => {
  usePersistQueryString(getStoresRoute(), { write: true });

  const { t } = useTranslation();

  const {
    sortBy,
  } = useStoresSortParams();

  const {
    values,
  } = useStoresFilterParams();

  const {
    page,
    perPage,
  } = usePaginationParams();

  const paginationParams = {
    page,
    perPage,
  };

  const sortParams = {
    sort: sortBy,
  };

  const paramsConfig: UseStoreParams = {
    filter: {
      subdomainId: values.subdomainId,
      countryId: values.countryId,
      emailPresence: values.emailPresence,
      brandId: values.brandId,
      name: values.name,
      companyId: values.companyId,
    },
    pagination: paginationParams,
    sort: sortParams,
  };

  const {
    dataMapping,
    isLoading: isMetaMappingLoading,
    error: metaMappingError,
  } = useFilteredStoresQueriesMapping(paramsConfig);

  const {
    stores,
    meta: scopedMeta,
    error: storesError,
    isLoading: isStoresLoading,
  } = useStores(paramsConfig);

  const totalEntriesUnfiltered = usePersistedValue(scopedMeta?.pagination.totalEntriesUnfiltered);

  return (
    <Container>
      <ViewModeSelectorContext permittedValues={permittedValues}>
        <PageSectionHeader
          headerSizeVariant='h3'
          subtitle={totalEntriesUnfiltered ? parens(totalEntriesUnfiltered) : ''}
          title={t('store:title')}>
          <StoresExportButton />
        </PageSectionHeader>
        <StoresView
          dataMapping={dataMapping}
          scopedMeta={scopedMeta}
          stores={stores}
          storesError={storesError}
          values={values}
          isMetaMappingLoading={isMetaMappingLoading}
          isStoresLoading={isStoresLoading}
          dataMappingError={metaMappingError}
        />
      </ViewModeSelectorContext>
    </Container>
  );
};
