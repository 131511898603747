import { useDataQuery } from '@/hooks/shared';
import { fetchSettingsChannels } from '@/queries/channel';
import { QUERY_KEYS } from '@/resources/dataKeys';

type UseChannelsOptions = {
  enabled?: boolean;
};

export const useChannels = (
  options: UseChannelsOptions = {},
) => {
  const {
    data: channels,
    ...rest
  } = useDataQuery(QUERY_KEYS.channels, fetchSettingsChannels, {
    enabled: options?.enabled,
  });

  return {
    channels,
    ...rest,
  };
};
