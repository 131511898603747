import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FilterEntry } from '@/components/shared/FilterEntry';
import { BotSubdomain } from '@/components/bot/BotSubdomain';
import { LengthyFilterEntry } from '@/components/shared/FilterEntry/LengthyFilterEntry';
import { FilterEntriesContainer } from '@/components/shared/FilterEntriesContainer';
import {
  getChannelNameBySubdomainId,
  getSubdomainById,
} from '@/components/bot/BotFilterEntries/utils';
import { CountryLogotype } from '@/components/shared/CountryLogotype';
import { StoresFiltersProps } from '@/components/store/StoresFilters/StoresFilters';
import { useBuildClearValue, useIsOnInstance } from '@/hooks/shared';
import { useStoreStatusLabel } from '@/hooks/stores';
import { useChannels } from '@/hooks/channel';
import { getBrandFilterEntry } from '@/utils/brand';
import { useCompanies } from '@/hooks/company';
import { type StoresFilterParams } from '@/types/store';
import { type AggregationEntry } from '@/types/shared';

type StoresFilterEntriesProps = {
  filterValues: StoresFilterParams;
  countries: AggregationEntry[] | undefined;
  aggregationMapping?: StoresFiltersProps['aggregationMapping'];
};

export const StoresFilterEntries = ({
  filterValues,
  countries,
  aggregationMapping,
}: StoresFilterEntriesProps) => {
  const { t } = useTranslation();
  const buildClearValue = useBuildClearValue<StoresFilterParams>();
  const { isCustomerInstance } = useIsOnInstance();

  const statusLabel = useStoreStatusLabel(filterValues.status);

  const getEmailLabel = () => {
    if (!filterValues?.emailPresence) {
      return null;
    }

    return (
      filterValues.emailPresence === 'missing'
        ? t('store:filterable.options.email.missing')
        : t('store:filterable.options.email.present')
    );
  };

  const {
    channels,
  } = useChannels();

  const {
    companies,
  } = useCompanies({}, { disabled: isCustomerInstance });

  const filterSubdomain = useMemo(() => {
    if (!filterValues.subdomainId || !channels) return;
    return getSubdomainById(channels, filterValues.subdomainId);
  }, [
    channels,
    filterValues.subdomainId,
  ]);

  const channelName = useMemo(() => {
    if (!filterValues.subdomainId || !channels) return;
    return getChannelNameBySubdomainId(channels, filterValues.subdomainId);
  }, [
    channels,
    filterValues.subdomainId,
  ]);

  const filterCountry = useMemo(() => {
    if (!filterValues.countryId || !countries) return;
    const countryEntry = countries.find((country) => String(country.id) === filterValues.countryId);

    return countryEntry?.label as string;
  }, [
    countries,
    filterValues.countryId,
  ]);

  const brandAgg = aggregationMapping?.brand;

  const filterBrand = useMemo(() => {
    if (!filterValues.brandId || !brandAgg) return;

    return getBrandFilterEntry({
      brandAgg,
      brandId: filterValues.brandId,
      t,
    });
  }, [
    t,
    brandAgg,
    filterValues,
  ]);

  const filterCompany = useMemo(() => {
    if (!filterValues.companyId || !companies) return;

    return companies.find((company) => company.id === filterValues.companyId);
  }, [
    companies,
    filterValues.companyId,
  ]);

  return (
    <FilterEntriesContainer>
      {
        filterValues.name &&
        <LengthyFilterEntry
          name={filterValues.name}
          entryLabel={t('store:filterable.labels.name')}
          onRemove={buildClearValue('name')}
        />
      }
      {
        filterSubdomain &&
        <FilterEntry onRemove={buildClearValue('subdomainId')}>
          <BotSubdomain
            name={channelName}
            url={filterSubdomain.attributes.url}
            nameSize='small'
          />
        </FilterEntry>
      }
      {
        filterBrand &&
        <LengthyFilterEntry
          name={filterBrand}
          entryLabel={t('store:filterable.labels.brand')}
          onRemove={buildClearValue('brandId')}
        />
      }
      {
        filterCompany &&
        <LengthyFilterEntry
          name={filterCompany.attributes.name}
          entryLabel={t('bot:filterable.labels.company')}
          onRemove={buildClearValue('companyId')}
        />
      }
      {
        filterCountry &&
        <LengthyFilterEntry
          name={
            <CountryLogotype countryName={String(filterCountry)} />
          }
          entryLabel={t('store:filterable.labels.country')}
          onRemove={buildClearValue('countryId')}
        />
      }
      {
        statusLabel &&
        <LengthyFilterEntry
          name={statusLabel}
          entryLabel={t('store:filterable.labels.status')}
          onRemove={buildClearValue('status')}
        />
      }
      {
        getEmailLabel() &&
        <LengthyFilterEntry
          name={getEmailLabel()}
          entryLabel={t('store:filterable.labels.email')}
          onRemove={buildClearValue('emailPresence')}
        />
      }
    </FilterEntriesContainer>
  );
};
