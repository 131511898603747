import { useDataQuery } from '@/hooks/shared';
import { fetchEnforcementPolicy } from '@/queries/enforcementPolicy';
import { QUERY_KEYS } from '@/resources/dataKeys';
import { EnforcementPoliciesFilterParams } from '@/types/enforcementPolicy';

export type UseEnforcementPolicyFilter = {
  filter?: Pick<EnforcementPoliciesFilterParams, 'companyId'>;
}

export const useEnforcementPolicy = (enforcementPolicyId: string | undefined) => {
  const {
    data,
    ...rest
  } = useDataQuery(
    [QUERY_KEYS.enforcementPolicies, enforcementPolicyId],
    fetchEnforcementPolicy,
    {
      enabled: !!enforcementPolicyId,
    },
  );

  return {
    enforcementPolicy: data,
    ...rest,
  };
};
