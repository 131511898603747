import { useTranslation } from 'react-i18next';

import { ExportButton } from '@/components/shared/ExportButton';
import { useCompanyStoresCsv, useStoresCsv } from '@/hooks/stores';
import { useStoresSortParams, useStoresFilterParams } from '@/hooks/stores';
import { useIsOnInstance } from '@/hooks/shared';

export const StoresExportButton = () => {
  const { t } = useTranslation();
  const {
    values,
  } = useStoresFilterParams();

  const {
    sortBy,
  } = useStoresSortParams();

  const sortParams = {
    sort: sortBy,
  };

  const { isAdminInstance } = useIsOnInstance();

  const useResource = isAdminInstance
    ? useStoresCsv
    : useCompanyStoresCsv;

  const {
    isFetching,
    refetch: fetchStoresCsv,
  } = useResource({
    filter: {
      subdomainId: values.subdomainId,
      countryId: values.countryId,
      status: values.status,
    },
    sort: sortParams,
  }, {
    enabled: false,
  });

  return (
    <ExportButton
      fileName={t('store:title')}
      notificationBody={t('store:successExportMessage')}
      isFetching={isFetching}
      fetchData={fetchStoresCsv}
    />
  );
};
