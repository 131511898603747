import { type TFunction } from 'i18next';

import type { FormikSubmitHandlerOptionsConfig } from '@/hooks/shared/useFormikMutationHandler';
import type { ApiDataError } from '@/types/shared';

export const translateFormikError = (
  error: ApiDataError,
  t: TFunction,
  tVars?: FormikSubmitHandlerOptionsConfig['notification']['tKeys'],
): string => {
  // form
  if (error.title === 'can\'t be blank') {
    return t('form:errors.required');
  }

  if (error.title === 'must be supplied if resulting policy definition will be inactive') {
    return t('form:errors.required');
  }

  if (error.title === 'has already been taken') {
    if (error.source?.pointer === '/data/relationships/company') {
      return t('report:form.errors.reportExistsForCompany');
    }

    return t('form:errors.notUniq');
  }

  if (error.title === 'has invalid format') {
    if (error.source?.pointer === '/data/attributes/hostname') {
      return t('company:form.errors.invalidHostnameFormat');
    }

    if (error.source?.pointer === '/data/attributes/email_address') {
      return t('form:errors.invalidEmailFormat');
    }
  }

  if (error.title === 'is missing a scheme (e.g. https)') {
    if (error.source?.pointer === '/data/attributes/search_term') {
      return t('bot:form.errors.missingScheme');
    }

    return t('form:errors.missingScheme');
  }

  if (error.title === 'a keyword must appear only once') {
    return t('form:errors.keywordNotUnique');
  }

  if (error.title === 'cannot be supplied as this name is already used within the company') {
    return t('form:errors.notUniq');
  }

  if (error.title === 'invalid host') {
    return t('form:errors.invalidHost');
  }

  if (error.title === 'Email Address does not exist.') {
    return t('form:errors.emailDoesNotExist');
  }

  const tooLongMatch = error.title?.match(/should be at most (?<count>\d+) character\(s\)/);

  if (tooLongMatch && tooLongMatch.groups?.count) {
    return t('form:errors.tooLong', error.title, { count: Number(tooLongMatch.groups.count) });
  }

  const tooShortMatch = error.title?.match(/should be at least (?<count>\d+) character\(s\)/);

  if (tooShortMatch && tooShortMatch.groups?.count) {
    return t('form:errors.tooShort', error.title, { count: Number(tooShortMatch.groups.count) });
  }

  if (error.title?.startsWith('isn\'t valid for subdomain')) {
    return t('form:errors.botUrlInvalidForChannel');
  }

  const enforcementOrTestPurchaseMatch = error
    .title
    ?.match(/'(?<itemNumber>\w+)' already in an active (Test Purchase|Enforcement)$/);

  if (enforcementOrTestPurchaseMatch?.groups) {
    const { itemNumber } = enforcementOrTestPurchaseMatch.groups;

    if (error.title.endsWith('Enforcement')) {
      return t('form:errors.itemNumberIds.alreadyInEnforcement', { itemNumber });
    }

    return t('form:errors.itemNumberIds.alreadyInTestPurchase', { itemNumber });
  }

  if (error.detail === 'Item number ids can\'t be empty') {
    return t('enforcement:messages.errors.assignTrademarkToAllProducts');
  }

  if (error.detail === 'Request Entity Too Large') {
    return t('form:errors.tooLarge');
  }

  if (error.title === 'appears to be down as it does not respond correctly when called') {
    return t('form:errors.notValidUrl');
  }

  if (error.detail === 'Body is invalid') {
    if (error.source?.pointer === '/data/attributes/body') {
      return t('form:errors.richText');
    }
  }

  // eslint-disable-next-line max-len
  if (error.title === 'cannot be supplied as this combination of subdomain and bot provider already exists for another Subdomain bot provider') {
    if (error.source?.pointer === '/data/relationships/bot_provider') {
      return t('form:errors.notUniqForSubdomain');
    }
  }

  if (error.title === 'is invalid') {
    if (error.source?.pointer === '/data/attributes/settings') {
      return t('form:errors.isNotValidJSON');
    }

    if (error.source?.pointer === '/data/relationships/dashboard') {
      return t('form:errors.invalidReportIdentifierFormat');
    }
  }

  // notifications
  if (error.title === 'cannot be deleted because it has associated subdomains') {
    if (error.source?.pointer === '/data/relationships/channel') {
      return t('notifications:errors:channel.associatedSubdomains');
    }

    if (error.source?.pointer === '/data/relationships/bot_provider') {
      return t('notifications:errors.dataProvider.associatedSubdomains');
    }
  }

  if (error.title === 'Conflict') {
    if (error.status === '409') {
      return t('notifications:errors:bot.enforcementOrTestPurchaseAgainst');
    }
  }

  if (error.title === 'cannot delete a trademark with associated bots') {
    if (error.source?.pointer === '/data/attributes/bot_associated') {
      return t('notifications:errors.trademark.associatedWithBot');
    }
  }

  if (error.title === 'cannot be deleted because it is active') {
    if (error.source?.pointer === '/data/attributes/subdomain_bot_provider') {
      return t('notifications:errors.subdomain.botProvider.isActive');
    }
  }

  if (error.title === 'cannot be deleted because it has associated bots') {
    return t('notifications:errors.subdomain.associatedBots');
  }

  if (error.title === 'cannot be deleted because it has associated bot providers') {
    return t('notifications:errors.subdomain.associatedBotProviders');
  }

  if (error.title === 'are still associated with this entry') {
    if (error.source?.pointer === '/data/attributes/enforcement_item_number_trademarks') {
      return t('notifications:errors.trademark.usedTrademarks');
    }

    const trademarkOfficeName = tVars?.trademarkOfficeName;

    return t('notifications:errors.trademarkOffice.usedTrademarks', { trademarkOfficeName });
  }

  if (error.title === 'notification_overrides_id is required') {
    return t('notifications:errors.notificationTemplate.notificationTemplateRequired');
  }

  if (error.title === 'must have the @ sign and no spaces') {
    return t('form:errors.invalidEmailFormat');
  }

  if (error.title === 'The uploaded file should be a .jpeg or .png') {
    return t('form:errors.invalidImageFileType');
  }

  if (error.status === '403') {
    return t('form:errors.base.forbidden');
  }

  return error.detail ?? t('common:errors.somethingWentWrongError');
};
