import { useTranslation } from 'react-i18next';

import { AnimatedLoadingDots } from '@/components/shared/AnimatedLoadingDots';
import { Button } from '@/components/shared/buttons';
import { notification } from '@/utils/notification';
import { getFileDownloadedDateSuffix } from '@/utils/file';
import { useCompanyStores, useStoresCsv } from '@/hooks/stores';
import { downloadBlob } from '@/utils/download';
import { useEnforcementPoliciesPdf } from '@/hooks/enforcementPolicy';

import style from './ExportButton.module.sass';

type RefetchFunction =
  | ReturnType<typeof useCompanyStores>['refetch']
  | ReturnType<typeof useStoresCsv>['refetch']
  | ReturnType<typeof useEnforcementPoliciesPdf>['refetch'];

export type ExportButtonProps = {
  isFetching: boolean;
  fetchData: RefetchFunction;
  notificationBody: string;
  fileName: string;
  fileType?: 'csv' | 'pdf';
};

export const ExportButton = ({
  isFetching,
  fetchData,
  notificationBody,
  fileName,
  fileType = 'csv',
}: ExportButtonProps) => {
  const { t, i18n } = useTranslation();

  const exportFileName = getFileDownloadedDateSuffix(
    t,
    i18n,
    fileName,
    fileType,
  );

  const downloadData = async () => {
    const { data, error } = await fetchData();

    if (error) {
      notification.danger({
        title: t('notifications:common.deleteErrorExportTitle'),
        body: t('common:errors.tryAgainLater'),
      });

      return;
    }

    const mimeType = fileType === 'pdf' ? 'application/pdf' : 'text/csv;charset=utf-16;';

    downloadBlob(
      data as unknown as BlobPart,
      exportFileName,
      mimeType,
    );

    notification.success({
      body: notificationBody,
    });
  };

  return (
    <Button
      variant='light'
      onClick={downloadData}
      className={style.exportLoading}>
      {
        isFetching
          ? (
            <span className={style.exportLoading}>
              {t('common:exporting')}
              <AnimatedLoadingDots />
            </span>
          )
          : t('common:export')
      }
    </Button>
  );
};
