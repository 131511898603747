import { useDataQuery } from '@/hooks/shared';
import { fetchChannel } from '@/queries/channel';
import { QUERY_KEYS } from '@/resources/dataKeys';

/*
  This hook is used to provide a channel / subdomains created in the application
  (whether it has an active data provider or not)
*/
export const useChannel = (channelId?: string) => {
  const {
    data: channel,
    ...rest
  } = useDataQuery([QUERY_KEYS.channel, channelId], fetchChannel);

  return {
    channel,
    ...rest,
  };
};
