import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { CompanyRemoteAdminBadge } from '@/components/company/CompanyRemoteAdminBadge';
import { DetailsTable } from '@/components/shared/DetailsTable';
import { ActionInfoByUser } from '@/components/shared/ActionInfoByUser';
import { LinkBadge } from '@/components/shared/LinkBadge';
import { CompanyAppLink } from '@/components/company/CompanyAppLink';
import { CountryLogotype } from '@/components/shared/CountryLogotype';
import { AddressLines } from '@/components/shared/AddressLines';
import { Button } from '@/components/shared/buttons';
import { removeProtocolFromUrl } from '@/utils/string';
import { getCompanyNotificationTemplatesRoute, getCreateCompanyEnforcementPolicyRoute } from '@/utils/routes';
import { useGetInstanceRoute, useIsOnInstance } from '@/hooks/shared';
import { useRbac } from '@/hooks/user';
import { useIsCompanyDomainLinkEnabled } from '@/hooks/company';
import { type DetailsTableConfig } from '@/types/tables';
import { type Company } from '@/types/company';

import style from './CompanySettings.module.sass';

type CompanySettingsProps = {
  company?: Company;
  isLoading?: boolean;
};

const cx = classNames.bind(style);

export const CompanySettings = ({
  company,
  isLoading,
}: CompanySettingsProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    isCustomerInstance,
    isAdminInstance,
  } = useIsOnInstance();

  const {
    getInstanceLoasRoute,
    getInstanceLoaCreateRoute,
    getInstanceEnforcementPoliciesRoute,
    getInstanceEnforcementPolicyPreviewRoute,
    getInstanceBrandsRoute,
    getInstanceBrandCreateRoute,
  } = useGetInstanceRoute();

  const companyWebsite = removeProtocolFromUrl(company?.attributes.websiteUrl);

  const isRemoteAdminAllowed = Boolean(company?.attributes.allowRemoteAdmin);
  const isDomainLinkEnabled = useIsCompanyDomainLinkEnabled(isRemoteAdminAllowed);

  const canManage = useRbac({
    isRemoteAdminAllowed,
    companyId: company?.id,
    rolesWhitelist: [
      'isGsAdmin',
      'isAdmin',
    ],
  });

  const renderLoaButton = () => {
    if (!company?.attributes.loaCount) {
      const navigateToCreateLoa = () => {
        navigate(getInstanceLoaCreateRoute());
      };

      return (
        <Button
          disabled={!canManage}
          onClick={navigateToCreateLoa}
          variant='secondary'
          sizeVariant='small'>
          {t('common:createNew')}
        </Button>
      );
    }

    return (
      <LinkBadge
        className={style.linkBadge}
        to={getInstanceLoasRoute()}>
        {t('loa:viewAllLoas')}
      </LinkBadge>
    );
  };

  const renderEnforcementPoliciesButton = () => {
    if (!company?.attributes.enforcementPolicyCount) {
      return (
        <Button
          disabled={!canManage}
          onClick={() => navigate(getCreateCompanyEnforcementPolicyRoute(company?.id))}
          variant='secondary'
          sizeVariant='small'>
          {t('common:createNew')}
        </Button>
      );
    }

    return (
      <LinkBadge
        className={style.linkBadge}
        to={getInstanceEnforcementPoliciesRoute()}>
        {t('company:details.enforcementPolicy.view')}
      </LinkBadge>
    );
  };

  const renderEnforcementPolicyButton = () => {
    if (!company?.attributes.enforcementsPolicyUrl) {
      return t('company:details.enforcementPolicy.empty');
    }

    return (
      <LinkBadge
        className={style.linkBadge}
        to={getInstanceEnforcementPolicyPreviewRoute(company.id)}>
        {t('company:details.enforcementPolicy.preview')}
      </LinkBadge>
    );
  };

  const renderBrandsButton = () => {
    if (!company?.attributes.brandCount) {
      const navigateToCreateLoa = () => {
        navigate(getInstanceBrandCreateRoute());
      };

      return (
        <Button
          disabled={!canManage}
          onClick={navigateToCreateLoa}
          variant='secondary'
          sizeVariant='small'>
          {t('common:createNew')}
        </Button>
      );
    }

    return (
      <LinkBadge
        className={style.linkBadge}
        to={getInstanceBrandsRoute()}>
        {t('brand:viewAll')}
      </LinkBadge>
    );
  };

  const createdByClassNames = cx('createdBy', { isAdminInstance });
  const updatedByClassNames = cx('updatedBy', { isAdminInstance });

  const config: DetailsTableConfig = {
    rows: [
      {
        header: t('company:attributes.ipOwner'),
        body: company?.attributes.ipOwner,
      },
      {
        header: t('company:attributes.domain'),
        body: (
          <CompanyAppLink
            className={style.link}
            hostname={company?.attributes.hostname || ''}
            enabled={isDomainLinkEnabled}
          />
        ),
      },
      {
        header: t('company:attributes.country'),
        body: (
          <CountryLogotype
            countryName={company?.attributes.country}
            className={style.companyCountry}
          />
        ),
      },
      {
        header: t('company:attributes.address'),
        body: (
          <AddressLines addressLine={company?.attributes.address} />
        ),
      },
      {
        header: t('company:attributes.authenticationMethod'),
        body: company?.attributes.authenticationMethod,
      },
      {
        header: t('company:attributes.allowRemoteAdmin'),
        body: (
          <CompanyRemoteAdminBadge allowRemoteAdmin={isRemoteAdminAllowed} />
        ),
      },
      {
        header: t('company:attributes.websiteUrl'),
        body: (
          <LinkBadge
            iconName='LinkOpen'
            className={style.linkBadge}
            href={company?.attributes.websiteUrl}>
            {companyWebsite}
          </LinkBadge>
        ),
      },
      {
        header: t('company:attributes.authorizationLetter'),
        body: renderLoaButton(),
      },
      {
        header: t('company:attributes.enforcementPolicy'),
        body: renderEnforcementPolicyButton(),
      },
      {
        header: t('company:attributes.enforcementPolicies'),
        body: renderEnforcementPoliciesButton(),
        hidden: isCustomerInstance,
      },
      {
        header: t('brand:brands'),
        body: renderBrandsButton(),
      },
      {
        header: t('company:attributes.notificationsTemplates'),
        body: (
          <LinkBadge
            className={style.linkBadge}
            to={getCompanyNotificationTemplatesRoute(company?.id)}>
            {t('notificationTemplate:notificationTemplatesLink')}
          </LinkBadge>
        ),
        hidden: isCustomerInstance,
      },
      {
        header: t('common:created'),
        body: (
          <ActionInfoByUser
            actionBy={company?.attributes.createdBy}
            text={company?.attributes.createdAt}
            nameBodySize='base'
          />
        ),
        skeletonsNumber: 2,
        skeletonsClassName: style.actionInfoSkeletons,
        className: createdByClassNames,
      },
      {
        header: t('common:updated'),
        body: (
          <ActionInfoByUser
            actionBy={company?.attributes.updatedBy}
            text={company?.attributes.updatedAt}
            nameBodySize='base'
          />
        ),
        skeletonsNumber: 2,
        skeletonsClassName: style.actionInfoSkeletons,
        className: updatedByClassNames,
      },
    ],
    bodySize: 'medium',
    isLoading,
  };

  return (
    <DetailsTable
      config={config}
      wrapperClassName={style.wrapper}
    />
  );
};
